<div class="inner-request-callback">
	<div class="inner-calendar">
		<mat-card appearance="outlined" [ngClass]="{ error: error }" class="pick-date-calendar-popup">
			<mat-calendar (selectedChange)="updateFormDate($event)" [minDate]="minDate" [selected]="form.get('date').value">
			</mat-calendar>
		</mat-card>
		<div class="hours-buttons">
			<button
				(click)="handleClickTime(item)"
				*ngFor="let item of hours"
				[ngClass]="{ selected: selectedHour == item }"
				tabindex="0"
			>
				{{ item }}
			</button>
		</div>
	</div>
	<button (click)="handleSchedule()" class="app-button" tabindex="0">
		<span class="title"> {{ "buttons.scheduleCallback" | translate }} </span>
	</button>
</div>
