import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { hidePopupAction, showPopupAction } from 'src/app/store/actions/popup.actions';
import { IAppState } from 'src/app/store/states/app.state';
import { TranslateService } from '@ngx-translate/core';

const DEFUALTTIME = '7:00 AM';

@Component({
	selector: 'app-request-callback',
	templateUrl: './request-callback.component.html',
	styleUrls: ['./request-callback.component.scss'],
})
export class RequestCallbackComponent implements OnInit {
	error: boolean = false;
	data: any;
	form: FormGroup;
	minDate: Date;
	hours: string[] = ['7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '13:00 PM', '14:00 PM'];
	selectedHour = DEFUALTTIME;

	constructor(
		private store: Store<IAppState>,
		private quoteDataService: QuoteDataService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.minDate = new Date();
		this.initform();
	}

	initform(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl('date', new FormControl({ value: null, disabled: false }, [Validators.required]));
		this.form.addControl('time', new FormControl({ value: DEFUALTTIME, disabled: false }, [Validators.required]));
	}

	updateFormDate(value: any) {
		this.error = false;
		this.form.get('date').setValue(value);
	}

	handleClickTime(time) {
		this.selectedHour = time;
		this.form.get('time').setValue(time);
	}

	handleSchedule() {
		if (this.form.value.date) {
			this.store.dispatch(hidePopupAction());
			this.store.dispatch(
				showPopupAction({
					componentName: 'thank-you-callback',
					payload: {
						fullScreen: false,
						desktopCenter: true,
						text: `${this.translateService.instant('popups.titles.thank-you-callback')} ${this.data.name}`,
						name: this.data.name,
						time: this.form.value.time,
						day: this.form.value.date._d.toLocaleDateString(),
						gtmLabel: 'CallBackPopUp',
					},
				})
			);
		} else {
			this.error = true;
		}
	}
}
